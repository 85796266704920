import React from "react";
import Header from "../components/NavBar";
import Footer from "../components/Footer";
import "./viewStyles/about.css";
// import Logo from "../assets/images/aboutus.jpg";
import Logo from "../assets/images/main-home-img/aboutus-banner.jpeg";
import SubHeader from "./subheader";
import CGPACalculator from "./CalculateMarks";

const About = () => {
  return (
    <React.Fragment>
      <Header />
      <SubHeader title="About Us" />
      <div id="aboutUs" className="container justify-content-start">
        <div className="row">
          <div className="col-md-4 col-xs-10 mt-2">
            <img className="img-responsive img-thumbnail" src={Logo} />
          </div>
          <div className="col-md-8 col-xs-12" id="aboutus_content">
            <h3 className="aboutus-title">WELCOME TO OUR Le Banyan de PEC</h3>
            <p className="para-aboutus">
              Le Banyan de Pondicherry Engineering College is a alumni based
              organization by the alumni of Pondicherry Engineering College. The
              Society was registered on 25th November, 2016 under the Societies
              Registration Act, 1860 with the S.No. 473/2016 and have obtained
              12A and 80G. Le Banyan de PEC, serves to the welfare of deserving
              PEC students and common citizens. Our main objectives are
              Education, Women Empowerment, Welfare and Environment. The
              projects related to the objectives will be executed both inside
              the college for the welfare of PEC students as well as to
              enlighten the livelihood of common citizen. 80 % of our operations
              and funds are geared towards the institution catered to and
              executed to deserving PEC Students.Le Banyan de PEC will pick up a
              few “Pet projects” which will be beneficial to the Students of PEC
              students and a few others deprived and deserving students from
              other students. With the blessing of the members of Le Banyan de
              PEC, the selected “Pet Projects” will be given higher priority and
              corpus fund will be built
            </p>
          </div>
        </div>
        <div className="row row-top">
          <div className="col-md-12">
            <p className="para-aboutus">
              to ensure these projects will be implemented for life time. With
              the blessing of the members of Le Banyan de PEC, the selected “Pet
              Projects” will be given higher priority and corpus fund will be
              built to ensure these projects will be implemented for life time.
              The “Pet projects” would cater to the welfare of the PEC students
              and students from other schools and colleges. Year over year,
              additional “Pet projects” will be considered based on the fund
              availability and support from alumni. Le Banyan de PEC ensures a
              proper project monitoring unit and a review update will be
              provided by the Joint- Secretaries. Le Banyan de PEC will follow
              an open book policy for its operation and will operate at minimum
              overhead. The balance sheet will be presented at the governing
              body meeting every 3 months and will be shared with all members of
              Le Banyan de PEC. Project Selection, Project projected cost and
              Project expense will be available via google docs. and will be
              updated on a weekly basis.
            </p>
            {/* <p className="para-aboutus"></p> */}
          </div>
          {/* <CGPACalculator /> */}
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default About;
