import React, { useEffect, useRef, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/NavBar";
import SubHeader from "./subheader";
import "./viewStyles/register.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  banyanRegisterApi,
  getUserInfoById,
  updatePersonalInfoById,
  updateProfielById,
} from "../serviceApi/Auth-Services";
import swal from "sweetalert";
import toast from "react-hot-toast";
import SpinnerWrapper from "./spinner";
import { TOP_EMAIL_DOMAINS, topEmailDomains } from "../serviceApi/constant";

const UserProfile = () => {
  console.log("userprofile");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("id");
  const navigate = useNavigate();
  const [getUser, setGetUser] = useState();
  const firstInputRef = useRef();
  const [isError, setIsError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    gender: "",
    address: "",
    occupation: "",
    course: "",
    branch: "",
    yearOfPassing: "",
    communicationMode: "",
    interestedTowards: ["Education", "Women Empowerment", "Welfare", "Environment"],
    contribution: "",
    role: "member",
    touched: {
      name: false,
      email: false,
      mobileNumber: false,
      occupation: false,
      course: false,
      branch: false,
      yearOfPassing: false,
      interestedTowards: false,
      address: false,
      contribution: false,
    },
  });
  const [isServer_Get, setIsServerData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    gender: "",
    address: "",
    occupation: "",
    course: "",
    branch: "",
    yearOfPassing: "",
    communicationMode: "",
    interestedTowards: ["Education", "Women Empowerment", "Welfare", "Environment"],
    contribution: "",
    role: "member",
  });

  const interestedOptions = ["Education", "Women Empowerment", "Welfare", "Environment"];

  useEffect(() => {
    const fetchUserInfo = async () => {
      setIsLoading(true);
      try {
        firstInputRef.current.focus();
        const responseData = await getUserInfoById(userId);
        if (responseData) {
          setFormData((prevData) => ({
            ...prevData,
            name: responseData.name || "",
            email: responseData.email || "",
            mobileNumber: responseData.mobileNumber || "",
            gender: responseData.gender || "",
            address: responseData.address || "",
            occupation: responseData.occupation || "",
            course: responseData.course || "",
            branch: responseData.branch || "",
            yearOfPassing: responseData.yearOfPassing || "",
            communicationMode: responseData.communicationMode || "",
            contribution: responseData.contribution || "",
          }));
          setIsServerData((prevData) => ({
            ...prevData,
            name: responseData.name || "",
            email: responseData.email || "",
            mobileNumber: responseData.mobileNumber || "",
            gender: responseData.gender || "",
            address: responseData.address || "",
            occupation: responseData.occupation || "",
            course: responseData.course || "",
            branch: responseData.branch || "",
            yearOfPassing: responseData.yearOfPassing || "",
            communicationMode: responseData.communicationMode || "",
            contribution: responseData.contribution || "",
          }));
          console.log(responseData, "userdata========>");
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        // swal("Failed!", error.message + " please try again later.", "error");
        console.log(error);
      }
    };

    // console.log("Fetching user info for ID:", getScholarshIdUser);
    fetchUserInfo();
  }, [userId]); // Added dependency

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === "radio") {
      // Handle checkboxes for gender selection
      setFormData({
        ...formData,
        [name]: value,
      });
    } else if (type === "checkbox") {
      if (checked) {
        // If checked, add the value to the array
        setFormData({
          ...formData,
          interestedTowards: [...formData.interestedTowards, value],
        });
      } else {
        // If unchecked, remove the value from the array
        setFormData({
          ...formData,
          interestedTowards: formData.interestedTowards.filter((item) => item !== value),
        });
      }
    } else {
      // Handle other input fields
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleInputBlur = (event) => {
    const { name } = event.target;

    // Mark the field as touched
    setFormData({
      ...formData,
      touched: {
        ...formData.touched,
        [name]: true,
      },
    });
  };

  const isFieldValid = (field) => {
    return formData[field] !== "";
  };
  const fromTouchedState =
    formData.name &&
    formData.email &&
    formData.password &&
    formData.repeatPassword &&
    formData.mobile &&
    formData.occupation &&
    formData.course &&
    formData.branch &&
    formData.interestedTowards &&
    formData.yearOfPassing &&
    formData.address &&
    formData.contribution;
  const disableButton = fromTouchedState === "";

  const onSignupHandler = (event) => {
    event.preventDefault();
    setIsLoading(true);
    // Check if all fields are pre-populated (adjust conditions as needed)
    const isAllFieldsPrePopulated =
      formData.name &&
      formData.email &&
      formData.password &&
      formData.repeatPassword &&
      formData.mobileNumber &&
      formData.gender &&
      formData.address &&
      formData.occupation &&
      formData.course &&
      formData.branch &&
      formData.yearOfPassing &&
      formData.communicationMode &&
      formData.memberShipType &&
      formData.interestedTowards.length > 0 &&
      formData.contribution;

    // If all fields are filled, show a popup and prevent submission
    const isValidMobile = /^[0-9]*$/.test(formData.mobileNumber);
    const isValidCourse = /^[a-zA-Z0-9\s]*$/.test(formData.course);
    const isValidOccupation = /^[a-zA-Z0-9\s]*$/.test(formData.occupation);
    const isValidBranch = /^[a-zA-Z0-9\s]*$/.test(formData.branch);
    const containsNumbers = /\d/.test(formData.name);
    const isValidName = /^[a-zA-Z0-9\s]*$/.test(formData.name);
    const containsNameLength = formData.name.length >= 3;
    const contributionIsValid = /^[0-9]*$/.test(formData.contribution);
    toast.dismiss();
    if (disableButton) {
      swal("Oops", `Please fill in the required fields`, "error", {
        button: "Try Again!",
      });
      setIsLoading(false);
      return;
    }
    if (containsNameLength) {
      console.log("Name is valid");
    } else {
      swal("Oops", `Please enter a valid name`, "error", {
        button: "Try Again!",
      });
      setIsLoading(false);
      return;
    }

    if (containsNumbers) {
      swal("Oops", `Numbers are not allowed in the name field`, "error", {
        button: "Try Again!",
      });
      setIsLoading(false);
      return;
    }

    if (!isValidMobile) {
      swal("Oops", "Mobile number should only contain digits", "error", {
        button: "Try Again!",
      });
      setIsLoading(false);
      return;
    }
    if (!isValidCourse) {
      swal("Oops", "Course name should not contain special characters", "error", {
        button: "Try Again!",
      });
      setIsLoading(false);
      return;
    }
    if (!isValidOccupation) {
      swal("Oops", "Occupation name should not contain special characters", "error", {
        button: "Try Again!",
      });
      setIsLoading(false);
      return;
    }

    if (formData.mobileNumber.length < 10) {
      swal("Oops", `Mobile number should not be more than 10 digits`, "error", {
        button: "Try Again!",
      });
      setIsLoading(false);
      return;
    }

    if (!contributionIsValid) {
      swal("Oops", "Contribution is invalid", "error", {
        button: "Try Again!",
      });
      setIsLoading(false);
      return;
    }

    if (formData.yearOfPassing.length !== 4 || isNaN(formData.yearOfPassing)) {
      swal("Oops", "Year of passing is invalid", "error", {
        button: "Try Again!",
      });
      setIsLoading(false);
      return;
    }

    if (formData.interestedTowards.length === 0) {
      swal("Oops", `Scheme Type cannot be blank.`, "error", {
        button: "Try Again!",
      });
      setIsLoading(false);
      return;
    }

    if (!isValidBranch) {
      swal("Oops", "Branch name should not contain special characters", "error", {
        button: "Try Again!",
      });
      setIsLoading(false);
      return;
    }
    if (!isValidName) {
      swal("Oops", "Name should not contain special characters", "error", {
        button: "Try Again!",
      });
      setIsLoading(false);
      return;
    }

    const email = formData.email.trim().toLowerCase();
    const domain = email.substring(email.lastIndexOf("@") + 1);

    let isValidDomain = false;
    for (const domainKey in TOP_EMAIL_DOMAINS) {
      if (domain === domainKey) {
        isValidDomain = true;
        break;
      }
    }
    console.log(isValidDomain);

    if (!isValidDomain) {
      setIsLoading(false);
      swal("Oops", `Please enter a valid email address`, "error", {
        button: "Try Again!",
      });
      return;
    }

    // if (!formData.email.includes("@gmail.com")) {
    //   setIsLoading(false);
    //   swal("Oops", `Please enter a valid email address`, "error", {
    //     button: "Try Again!",
    //   });
    //   return;
    // }
    const payload = {
      ...(isServer_Get.name != formData.name ? { name: formData.name } : {}),
      ...(isServer_Get.email != formData.email ? { email: formData.email } : {}),
      // ...isServer_Get.password!=formData.password ?{password: formData.password}:{},
      // ...isServer_Get.repeatPassword!=formData.repeatPassword ?{repeatPassword: formData.repeatPassword}:{},
      ...(isServer_Get.mobileNumber != formData.mobileNumber
        ? { mobileNumber: formData.mobileNumber }
        : {}),
      ...(isServer_Get.gender != formData.gender ? { gender: formData.gender } : {}),
      ...(isServer_Get.address != formData.address ? { address: formData.address } : {}),
      ...(isServer_Get.occupation != formData.occupation
        ? { occupation: formData.occupation }
        : {}),
      ...(isServer_Get.course != formData.course ? { course: formData.course } : {}),
      ...(isServer_Get.branch != formData.branch ? { branch: formData.branch } : {}),
      ...(isServer_Get.yearOfPassing != formData.yearOfPassing
        ? { yearOfPassing: formData.yearOfPassing }
        : {}),
      ...(isServer_Get.communicationMode != formData.communicationMode
        ? { communicationMode: formData.communicationMode }
        : {}),
      // ...isServer_Get.memberShipType!=formData.memberShipType ?{memberShipType: formData.memberShipType}:{},
      ...(isServer_Get.contribution != formData.contribution
        ? { contribution: formData.contribution }
        : {}),
      // ...isServer_Get.gender!=formData.gender ?{gender: formData.gender}:{},
      // ...isServer_Get.gender!=formData.gender ?{gender: formData.gender}:{},

      // gender: formData.gender,
      // address: formData.address,
      // occupation: formData.occupation,
      // course: formData.course,
      // branch: formData.branch,
      // yearOfPassing: formData.yearOfPassing,
      // communicationMode: formData.communicationMode,
      // membershipType: formData.memberShipType,
      // interestedTowards: formData.interestedTowards,
      // contribution: parseInt(formData.contribution),
      // role: formData.role,
    };

    updateProfielById(payload, userId)
      .then((response) => {
        toast.dismiss();
        console.log("register response ==> ", response.message);
        if (response) {
          setIsLoading(false);
          setIsServerData(formData);
          toast.success(response.message);
          //  localStorage.setItem("userDetails", response._id);
        } else {
          setIsError(response.message);
          setIsLoading(false);
          toast.error(response.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setIsLoading(false);
        console.log(err.message);
      });
  };

  return (
    <React.Fragment>
      <Header />
      <SubHeader title="Become a Member" />
      {isLoading && <SpinnerWrapper />}
      <div className="site-signup container ">
        <div className="row">
          <div className="signup-form-container col-md-12">
            <div className="f-title-description b-title-description title-signup">
              Update Profile
            </div>
            <form onSubmit={onSignupHandler}>
              <div className="container mt-4">
                <div className="row mx-5" id="register_one">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        for="inputField1"
                        className={`col-md-4 right-aligned-label ${
                          formData.touched.name && !isFieldValid("name")
                            ? "col-form-label red-label"
                            : "col-form-label text-white"
                        }`}
                      >
                        Name
                      </label>
                      <div className="col-md-7">
                        <input
                          className={`form-control ${
                            formData.touched.name && !isFieldValid("name")
                              ? "red-border"
                              : "form-control custom-shadow"
                          }`}
                          name="name"
                          // placeholder="Enter Your Full Name"
                          type="text"
                          value={formData.name}
                          ref={firstInputRef}
                          onChange={handleInputChange}
                          onBlur={handleInputBlur}
                          maxLength={50}
                          autoComplete="off"
                          spellCheck={false}
                        />
                        {formData.touched.name && !isFieldValid("name") && (
                          <div className="text-danger err_msg">Name cannot be blank.</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        for="inputField7"
                        className={`col-md-4  right-aligned-label ${
                          formData.touched.occupation && !isFieldValid("occupation")
                            ? "col-form-label red-label"
                            : "col-form-label text-white"
                        }`}
                      >
                        Occupation
                      </label>
                      <div className="col-md-7">
                        <input
                          className={`form-control ${
                            formData.touched.occupation && !isFieldValid("occupation")
                              ? "red-border"
                              : "form-control custom-shadow"
                          }`}
                          name="occupation"
                          // placeholder="Enter Your occupation"
                          type="occupation"
                          value={formData.occupation}
                          onChange={handleInputChange}
                          onBlur={handleInputBlur}
                          autoComplete="off"
                          maxLength={30}
                        />
                        {formData.touched.occupation && !isFieldValid("occupation") && (
                          <div className="text-danger err_msg">
                            Occupation cannot be blank.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container mt-4">
                <div className="row mx-5" id="register_one">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        for="inputField7"
                        className={`col-md-4 right-aligned-label ${
                          formData.touched.email && !isFieldValid("email")
                            ? "col-form-label red-label"
                            : "col-form-label text-white"
                        }`}
                      >
                        Email
                      </label>
                      <div className="col-md-7">
                        <input
                          className={`form-control ${
                            formData.touched.email && !isFieldValid("email")
                              ? "red-border"
                              : "form-control custom-shadow"
                          } ${true ? "read-only" : ""}`} // Add "read-only" class here
                          name="email"
                          // placeholder="Enter Your Email"
                          type="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          onBlur={handleInputBlur}
                          autoComplete="off"
                          readOnly
                        />
                        {formData.touched.email && !isFieldValid("email") && (
                          <div className="text-danger err_msg">Email cannot be blank.</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        for="inputField7"
                        className={`col-md-4 right-aligned-label ${
                          formData.touched.course && !isFieldValid("course")
                            ? "col-form-label red-label"
                            : "col-form-label text-white"
                        }`}
                      >
                        Course
                      </label>
                      <div className="col-md-7">
                        <input
                          // placeholder="Enter Your Degree"
                          name="course"
                          maxLength={50}
                          type="text"
                          value={formData.course}
                          onChange={handleInputChange}
                          onBlur={handleInputBlur}
                          className={`form-control ${
                            formData.touched.course && !isFieldValid("course")
                              ? "red-border"
                              : "form-control custom-shadow"
                          }`}
                          autoComplete="off"
                        />
                        {formData.touched.course && !isFieldValid("course") && (
                          <div className="text-danger err_msg">Course cannot be blank.</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container mt-4">
                <div className="row mx-5" id="register_one">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        for="inputField7"
                        className={`col-md-4 right-aligned-label ${
                          formData.touched.branch && !isFieldValid("branch")
                            ? "col-form-label red-label"
                            : "col-form-label text-white"
                        }`}
                      >
                        Branch
                      </label>
                      <div className="col-md-7">
                        <input
                          // placeholder="Enter Your Branch/Specialization"
                          name="branch"
                          type="text"
                          value={formData.branch}
                          onChange={handleInputChange}
                          onBlur={handleInputBlur}
                          className={`form-control ${
                            formData.touched.branch && !isFieldValid("branch")
                              ? "red-border"
                              : "form-control custom-shadow"
                          }`}
                          autoComplete="off"
                          maxLength={40}
                        />
                        {formData.touched.branch && !isFieldValid("branch") && (
                          <div className="text-danger err_msg">Branch cannot be blank.</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        for="inputField7"
                        className="col-md-4 right-aligned-label col-form-label text-white"
                      >
                        Communication
                      </label>
                      <div className="col-md-6 mt-2">
                        <label className="radio-inline com-mode text-white">
                          <input
                            className="radio"
                            type="radio"
                            name="communicationMode"
                            id="email"
                            value="email"
                            checked={formData.communicationMode === "email"}
                            onChange={handleInputChange}
                            autoComplete="off"
                          />{" "}
                          Email
                        </label>
                        <label className="radio-inline mx-3 text-white">
                          <input
                            className="radio"
                            type="radio"
                            name="communicationMode"
                            id="mobile"
                            value="mobile"
                            checked={formData.communicationMode === "mobile"}
                            onChange={handleInputChange}
                          />{" "}
                          Mobile
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container mt-4">
                <div className="row mx-5" id="register_one">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        for="inputField1"
                        className={`col-md-4 right-aligned-label ${
                          formData.touched.yearOfPassing && !isFieldValid("yearOfPassing")
                            ? "col-form-label red-label"
                            : "col-form-label text-white"
                        }`}
                      >
                        Year of passing
                      </label>
                      <div className="col-md-7">
                        <input
                          // placeholder="Year in which you passed"
                          name="yearOfPassing"
                          type="text"
                          value={formData.yearOfPassing}
                          onChange={handleInputChange}
                          onBlur={handleInputBlur}
                          className={`form-control ${
                            formData.touched.yearOfPassing && !isFieldValid("yearOfPassing")
                              ? "red-border"
                              : "form-control custom-shadow"
                          }`}
                          autoComplete="off"
                          maxLength={4}
                        />
                        {formData.touched.yearOfPassing && !isFieldValid("yearOfPassing") && (
                          <div className="text-danger err_msg">
                            Year Of Passing cannot be blank.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        for="inputField7"
                        className={`col-md-4 right-aligned-label col-form-label text-white`}
                        //   ${
                        //   formData.touched.contribution && !isFieldValid("contribution")
                        //     ? "col-form-label red-label"
                        //     : "col-form-label text-white"
                        // }`}
                      >
                        Contribution Amount
                      </label>
                      <div className="col-md-7">
                        <div id="signupform-mem-type" className="text-white mt-2">
                          <input
                            // placeholder="Enter Your Branch/Specialization"
                            name="contribution"
                            type="text"
                            value={formData.contribution}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            className={`form-control ${
                              formData.touched.contribution && !isFieldValid("contribution")
                                ? "red-border"
                                : "form-control custom-shadow"
                            }`}
                            autoComplete="off"
                            maxLength={4}
                          />
                          {/* {formData.touched.contribution && !isFieldValid("contribution") && (
                            <div className="text-danger err_msg">
                              Contribution cannot be blank.
                            </div>
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container mt-4">
                <div className="row mx-5" id="register_one">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        for="inputField1"
                        className={`col-md-4 right-aligned-label ${
                          formData.touched.mobileNumber && !isFieldValid("mobileNumber")
                            ? "col-form-label red-label"
                            : "col-form-label text-white"
                        }`}
                      >
                        Mobile
                      </label>
                      <div className="col-md-7">
                        <input
                          className={`form-control ${
                            formData.touched.mobileNumber && !isFieldValid("mobileNumber")
                              ? "red-border"
                              : "form-control custom-shadow"
                          }`}
                          name="mobileNumber"
                          // placeholder="Enter Your Mobile Number"
                          type="text"
                          value={formData.mobileNumber}
                          onChange={handleInputChange}
                          onBlur={handleInputBlur}
                          autoComplete="off"
                          maxLength={10}
                        />
                        {formData.touched.mobileNumber && !isFieldValid("mobileNumber") && (
                          <div className="text-danger err_msg">Mobile cannot be blank.</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
              <div className="container mt-4">
                <div className="row mx-5" id="register_one">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        for="inputField1"
                        className="col-md-4 right-aligned-label col-form-label text-white"
                      >
                        Gender
                      </label>
                      <div className="col-md-6 mt-2">
                        <label className="radio-inline text-white">
                          <input
                            type="radio"
                            id="male"
                            name="gender"
                            value="male"
                            checked={formData.gender === "male"}
                            onChange={handleInputChange}
                          />{" "}
                          Male
                        </label>
                        <label className="radio-inline mx-3 text-white">
                          <input
                            type="radio"
                            id="female"
                            name="gender"
                            value="female"
                            checked={formData.gender === "female"}
                            onChange={handleInputChange}
                          />{" "}
                          Female
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
              <div className="container mt-4">
                <div className="row mx-5" id="register_one">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        for="inputField1"
                        className={`col-md-4 right-aligned-label ${
                          formData.touched.address && !isFieldValid("address")
                            ? "col-form-label red-label"
                            : "col-form-label text-white"
                        }`}
                      >
                        Address
                      </label>
                      <div className="col-md-7">
                        <textarea
                          style={{
                            height: "100px",
                          }}
                          type="text"
                          name="address"
                          className={`form-control ${
                            formData.touched.address && !isFieldValid("address")
                              ? "red-border"
                              : "form-control custom-shadow"
                          }`}
                          rows="4"
                          cols="50"
                          value={formData.address}
                          onChange={handleInputChange}
                          onBlur={handleInputBlur}
                          autoComplete="off"
                          maxLength={100}
                          // placeholder="Enter Your full address"
                        />
                        {formData.touched.address && !isFieldValid("address") && (
                          <div className="text-danger err_msg">Address cannot be blank.</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex justify-content-center ">
                <div className="col-10 mt-4" style={{ marginLeft: "-30px" }}>
                  <div className="col-12 d-flex justify-content-center mt-4">
                    <button
                      type="submit"
                      className="btn btn-block btn-success"
                      name="signup-button"
                    >
                      Submit
                    </button>
                  </div>
                  {/* <div
                    className="register-submit mt-4 mb-5 d-flex justify-content-end align-items-end"
                    style={{ marginLeft: "10.8vw" }}
                  ></div> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default UserProfile;
