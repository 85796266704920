export const TOP_EMAIL_DOMAINS = {
  "gmail.com": true,
  "yahoo.com": true,
  "hotmail.com": true,
  "outlook.com": true,
  "aol.com": true,
  "icloud.com": true,
  "mail.com": true,
  "live.com": true,
  "msn.com": true,
  "yahoo.co.jp": true,
  "comcast.net": true,
  "qq.com": true,
  "163.com": true,
  "163.net": true,
  "126.com": true,
  "sina.com": true,
  "yahoo.co.uk": true,
  "bellsouth.net": true,
  "yahoo.com.br": true,
  "163.com": true,
  "126.com": true,
  "sina.com": true,
  "yahoo.co.uk": true,
  "bellsouth.net": true,
  "yahoo.com.br": true,
  "rocketmail.com": true,
  "yahoo.co.in": true,
  "verizon.net": true,
  "yahoo.ca": true,
  "live.co.uk": true,
  "aol.co.uk": true,
  "cox.net": true,
  "yahoo.fr": true,
  "hotmail.co.uk": true,
  "ymail.com": true,
  "yahoo.com.au": true,
  "earthlink.net": true,
  "optonline.net": true,
  "me.com": true,
  "mail.ru": true,
  "sbcglobal.net": true,
  "shaw.ca": true,
  "yahoo.es": true,
  "att.net": true,
  "rogers.com": true,
  "yahoo.de": true,
  "btinternet.com": true,
  "centurylink.net": true,
  "yahoo.co.id": true,
  "web.de": true,
  "charter.net": true,
  "gmx.com": true,
  "windstream.net": true,
  "mac.com": true,
  "blueyonder.co.uk": true,
  "telus.net": true,
  "libero.it": true,
  "mail.yahoo.com": true,
  "terra.com.br": true,
  "rambler.ru": true,
  "netzero.net": true,
  "alice.it": true,
  "sky.com": true,
  "frontier.com": true,
  "videotron.ca": true,
  "gmx.de": true,
  "t-online.de": true,
  "earthlink.net": true,
  "optonline.net": true,
  "me.com": true,
  "mail.ru": true,
  "sbcglobal.net": true,
  "shaw.ca": true,
  "yahoo.es": true,
  "att.net": true,
  "rogers.com": true,
  "yahoo.de": true,
  "btinternet.com": true,
  "centurylink.net": true,
  "yahoo.co.id": true,
  "web.de": true,
  "charter.net": true,
  "gmx.com": true,
  "windstream.net": true,
  "mac.com": true,
  "blueyonder.co.uk": true,
  "telus.net": true,
  "libero.it": true,
  "mail.yahoo.com": true,
  "terra.com.br": true,
  "rambler.ru": true,
  "rediffmail.com": true,
  "netzero.net": true,
  "alice.it": true,
  "sky.com": true,
  "frontier.com": true,
  "videotron.ca": true,
  "gmx.de": true,
  "t-online.de": true,
  "ptuniv.edu.in": true,
  "pec.edu": true,
};

export const popularBranches = [
  "ECE",
  "CSE",
  "EEE",
  "IT",
  "MECH",
  "CIVIL",
  "CHEM",
  "E&I",
  "Mechatronics",
  "MCA",
  // "Computer Science",
  // "Electrical Engineering",
  // "Mechanical Engineering",
  // "Civil Engineering",
  // "Chemical Engineering",
  // "Biomedical Engineering",
  // "Aerospace Engineering",
  // "Mathematics",
  // "Physics",
  // "Chemistry",
  // "Biology",
  // "Environmental Science",
  // "Psychology",
  // "Sociology",
  // "Economics",
  // "Political Science",
  // "History",
  // "English Literature",
  // "Business Administration",
  // "Finance",
  // "Accounting",
  // "Marketing",
  // "Information Technology",
  // "Data Science",
  // "Artificial Intelligence",
  // "Medicine",
  // "Nursing",
  // "Pharmacy",
  // "Law",
  // "Education",
];

export const educationDropdown = [{value: "firstYear", label: "First Year"}, {value:"secondYear", label: "Second Year"}, {value:"thirdYear", label: "Third Year"}, {value:"fourthYear", label: "Fourth Year"}, {value:"MCA", label: "MCA"}];

// Testimonial Review

export const TESTIMONIAL_CONTENTS = [
  {
    Id: Math.random().toString(),
    name: `Mohanraj.G`,
    description: `I am writing to convey my deepest appreciation for the outstanding scholarship provided by Le Banyan De Pec during the academic year 2022-2023. 
    This scholarship significantly reduced the financial burden of my college education and allowed me to commit more time in my academic studies. I am deeply thankful for this opportunity, and I am committed to making the most of it.
    Thank you again for your encouragement and faith in my ability to succeed`,
  },
  {
    Id: Math.random().toString(),
    name: `Pawan Kumar`,
    description: `I am writing to express my heartfelt gratitude for the generous scholarship I have received from Le Banyan De Pec. I had received the scholarship amount of Rs18851 on 4 Jan 2023.
    This scholarship will significantly alleviate the financial burden of my education and allow me to focus more on my academic pursuits. I am sincerely grateful for this incredible opportunity and committed to making the most of it.
    Thank you once again for your support and belief in my abilities`,
  },
  {
    Id: Math.random().toString(),
    name: `K Pandeeswari`,
    description: `I'm Pandeeswari.K who studied B.Tech (Information Technology) in the period 2019-2023. 
    I received Rs.6000 as Scholarship from Le Banyan de PEC Association for paying my Exam fees.I received this
    scholarship because I didn't have any academic backlogs. This scholarship helped me to pay my fees at the 
    right time and these kinds of scholarships motivate students to maintain consistent grades and help students
    with financial issues to focus on their academics. I'm always grateful to LE BANYAN DE PEC association for 
    providing me with this scholarship. In the future, I also wish to join this association to provide financial 
    support for students who are consistent in their academics and financial issues`,
  },
  {
    Id: Math.random().toString(),
    name: `Gayathri R`,
    description: `Greetings of the day, I hope this email finds you well. I am writing to express my sincere gratitude for the credit of Rs.6000/- towards 
    my exam fees through Le Banyan de PEC scholarship. I am truly honored and immensely grateful to be a recipient of this generous scholarship. 
    I would also like to extend my thanks to all individuals involved in making this scholarship program possible. Wishing you continued success 
    in your mission to uplift students and make a positive impact on their lives`,
  },
  {
    Id: Math.random().toString(),
    name: `A Prudhvi Raj`,
    description: `I am ARADADI PRUDHVI RAJ from (19IT1005)  IT dept. I received a scholarship from LE BANYAN DE PEC of Rs. 6000 the 6th and 7th semesters. 
    This scholarship helped me a lot in paying the semester examination fee. And I am very thankful to LE BANYAN DE PEC`,
  },
  {
    Id: Math.random().toString(),
    name: `Maddela Ravi Kumar`,
    description: `
    I am writing to express my heartfelt gratitude for the generous scholarship I have received from Le Banyan De Pec. I had received the scholarship amount of Rs.5000 on 14-DEC-2022 and Rs.1000 on 10-MAY-2023. 
    This scholarship has significantly alleviated the financial burden of my education and allowed me to focus more on my academics. I am sincerely grateful for this incredible opportunity and committed to making the most of it.
    Currently, I am doing an internship in KPIT technologies and my overall CGPA is 8.12. I thank Le banyan de PEC organizers for helping me.
    Thank you once again for your support and belief in my abilities`,
  },
  {
    Id: Math.random().toString(),
    name: `Hariprasad.Z`,
    description: `
    I, Hariprasad one of the "Merit Scholarship recipients of Le Banyan de PEC", would like to take this opportunity to express my heartfelt gratitude to the Le Banyan contributors. 
    I am committed to utilizing the knowledge and skills acquired during my College days to make a positive impact in my field and society as a whole. Once again, thank you for believing 
    me and investing in my future. I got placed via campus recruitment in one of the top MNC`,
  },
  {
    Id: Math.random().toString(),
    name: `Tinku Kumar`,
    description: `
    Dear Banyan Family,
    I hope this message finds you well. I am writing to share some updates regarding my current endeavors. 
    Presently, I am diligently preparing for the GATE exam, aiming to further my education and career prospects. Despite being placed at TCS and MBIT Wireless Private Limited, I have made the decision to pursue higher education after one year of employment.
    I am pleased to inform you that my final academic CGPA stands at an impressive 9.09 without honors, and 9.27 with honors degree. I am truly grateful for the support and guidance provided by the dedicated contributors within the Banyan community, who have continually assisted students in their academic pursuits.
    Thank you once again to all the members of the Banyan Family for your unwavering assistance and encouragement.
    `,
  },
  {
    Id: Math.random().toString(),
    name: `Anes J`,
    description: `
    Dear  Le Banyan de PEC,
    I am writing to express my heartfelt gratitude for the scholarship
    awarded to me. Your generosity has made a significant impact on my
    academic journey, and I am truly grateful for the support.
    Currently, I am working as a Solution Engineer at Vunet Systems,
    leveraging my skills and knowledge acquired during my B.Tech studies,
    where I graduated with a CGPA of 7.7. 
    This scholarship not only eases
    the financial burden but also motivates me to strive for excellence in
    my career and academic pursuits.
    Thank you once again for the opportunity.
`,
  },
];

// Dashboard sheets links

export const BENEFICIARY =
  "https://docs.google.com/spreadsheets/d/1TRDlxdBPrWTHood7M7ihj06R0YS4ZnMf/edit#gid=1612995372";

export const CONTRIBUTION =
  "https://docs.google.com/spreadsheets/d/1_XbX9AFuubOToWK1sCj1rcrkTmo5DZL-oPuR6XIxMkg/edit?usp=drive_link";

export const FINANCIAL =
  "https://docs.google.com/spreadsheets/d/1nbDPSf9ywONbgWDUtAiDLIrhpnIBUEWUrPoZLQ5VPgU/edit?usp=drive_link";

export const REGISTERED_MEMBERS =
  "https://docs.google.com/spreadsheets/d/1fXUqNFVwP3scqX7a549tOzNVJq7brbDjT7wDdopxxIg/edit?usp=drive_link";

export const EDUCATION_FUNDS =
  "https://docs.google.com/spreadsheets/d/1ZlBqMvAayTg79ZgW7H8ClCTAP1hKaX9vvVvhyLinlGE/edit?usp=drive_link";

// Form Validations Schema: Name
export const VALIDATE_NAME = "Name is required";
export const VALIDATE_NAME_NUMBERS = "Name should not contain numbers and symbols";
export const VALIDATE_NAME_LENGTH = "Name should be at least 3 characters";
export const VALIDATE_NAME_MAX = "Name should not exceed 26 characters";
export const VALIDATE_WHITE_SPACE = "Name should not contain blank spaces";

// Form Validations Schema: Register Number
export const VALIDATE_REGISTER_NUMBER = "Register Number is required";

// Form Validations Schema: Email
export const VALIDATE_EMAIL = "Email is required";
export const VALIDATE_EMAIL_INVALID = "Invalid email address";
export const NUMBER_NOT_ALLOWED_EMAIL = "Email should not start with a number";
export const SPACE_EMAIL_VALIDATION = "Email should not contain blank spaces";

// Form Validations Schema: Residential Address
export const VALIDATE_ADDRESS = "Address is required";
export const CHARACTER_VALIDATION_ADDRESS = "Address should not exceed 100 characters";

// Form Validations Schema: Semester
export const VALIDATE_SEMESTER = "Semester is required";
